import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => ({
  image: state.searchGptKeywords.keywords_image,
})

const mapDispatch = (dispatch: any) => ({
  onClose() {
    dispatch(
      actions.searchGptKeywords.SearchGptKeywordsStoreKeywordsImage({
        image: null,
      })
    )
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
